import React, { useEffect, useState } from 'react'
import { Flex, Textarea, Input, Button, Heading, Text, Label, Radio } from 'theme-ui'
import ContactWidget from '../../General/ContactDetails'
import cloudinaryImageOptimizer from '../../../../../../gatsby-theme-minimal/src/helpers/cloudinaryImageOptimizer'

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  locationIndex,
  isMultiLocationSite
}) {
  const { name, street, city, state, zip, phone } = businessData
  const { containerStyles, title, subtitle, text, contactDetails, email, backgroundImages } = options

  const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const formName = `${name}-${city}`

  const styles = containerStyles ? JSON.parse(containerStyles) : {}
  const variant = variantName ? variantName : 'contactFormV1'

  useEffect(() => {
    if (backgroundImages) {
      setCloudinaryImage(
        cloudinaryImageOptimizer(
          backgroundImages[locationIndex],
          window.innerWidth < 1800 ? window.innerWidth + 500 : window.innerWidth
        )
      )
    }
    return () => {}
  }, [])

  // const { formName, variantName, city, isMultiLocationSite, testProp } = props
  // console.log(props)
  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        background: backgroundImages
          ? `linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url('${cloudinaryImage ? cloudinaryImage : ''}')`
          : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles
      }}
    >
      {title && (
        <Heading as="h2" variant={`${variant}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h3" variant={`${variant}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as="p" variant={`${variant}.text`} className="text">
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}
      <Flex
        as="form"
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactform'} //  this can not be dyanmic form names and details must be static      method='POST'
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input type="hidden" name="form-name" value={formName ? formName : 'contactform'} />
        <Input
          variant={`${variantName}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Name"
          className="inputField name"
        />
        <Input
          variant={`${variantName}.email`}
          type="email"
          name="email"
          id="email"
          mb={3}
          placeholder="Email"
          className="inputField email"
        />

        <Input
          variant={`${variantName}.name`}
          name="phone"
          type="text"
          id="phone"
          mb={3}
          placeholder="Phone Number"
          className="inputField phone"
        />
        <Input
          variant={`${variantName}.name`}
          name="address"
          type="text"
          id="address"
          mb={3}
          placeholder="Address"
          className="inputField addressinput"
          sx={{ display: 'flex' }}
        />
        <Input
          variant={`${variantName}.name`}
          name="age"
          type="number"
          id="age"
          mb={3}
          placeholder="Age"
          className="inputField age"
        />
        {/* <Label>Gender</Label>
      <Flex sx={{ margin: '0.5rem 0rem' }}>
        <Label sx={{ width: '48%', svg: { color: 'white', fill: 'white' } }}>
          <Radio name='gender' value='true' defaultChecked={true} />
          Male
        </Label>

        <Label sx={{ width: '48%', svg: { color: 'white', fill: 'white' } }}>
          <Radio name='gender' value='false' />
          Female
        </Label>
      </Flex> */}

        <Textarea
          name="message"
          id="message"
          variant={`${variantName}.message`}
          rows="6"
          mb={3}
          placeholder="Fitness Goals and Questions"
          className="textField message"
        />
        {isMultiLocationSite ? (
          <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
        ) : (
          ''
        )}
        <Button className="button submitBtn" type="submit">
          Submit
        </Button>
      </Flex>
    </Flex>
  )
}
